<template>
  <div class="app">
    <img src="./assets/main.png">
    <div class="bottom">
      CopyRight @2023 郑州赛鸟信息科技有限公司 <a href="https://beian.miit.gov.cn">备案号：豫ICP备2021025060号</a>
    </div>
  </div>
</template>

<style>
#app,html,body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.app img{
  width: 100%;
  height: auto;
}
.bottom{
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.bottom a {
  color: black;
  font-size: 13px;
}
</style>
